.trackerDetailed {
  display: flex;
  // border: 1px solid red;
  height: 90%;

  & > div {
    width: 50%;
    // border: 1px solid blue;
  }

  .trackerDetailed-left {
    padding-right: 4rem;
    overflow-y: scroll;
    padding-bottom: 5rem;
    border-right: 1px solid #e7e7e7;

    & > div {
      margin-top: 3rem;
      .title {
        font-size: 0.9rem;
        font-weight: 500;
        color: #212529;
      }

      .cardContainer {
        display: flex;
        gap: 1rem;
        background: #ffffff;
        border: 1px solid #e7e7e7;
        border-radius: 10px;
        align-items: center;
        padding: 1rem 1.5rem;
        margin-top: 1rem;

        & > .roundedimage {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        & > .squareimage {
          width: 40px;
          height: 40px;
          border-radius: 5px;
        }

        .contentDiv {
          color: #212529;
          & > :nth-child(1) {
            font-size: 1.1rem;
            font-weight: 700;
          }

          & > :nth-child(2) {
            font-size: 0.8rem;
            font-weight: 400;
            margin-top: 2.5px;
          }
        }
      }

      .detailsContainer {
        margin: 3rem 0;

        & > div {
          display: flex;
          justify-content: space-between;
          font-size: 0.9rem;
          color: #212529;
          margin: 1rem 0;
        }
      }
    }
  }
  .trackerDetailed-right {
    padding-left: 4rem;
    overflow-y: scroll;
    padding-bottom: 5rem;
  }
}

.stepDiv {
  padding: 3rem 0;
  border-bottom: 0.5px solid #e5e5e5;
  color: #212529;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  & > .title {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 30px;
  }

  & > .para {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 25px;
  }
  & > .btn {
    font-size: 0.8rem;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #9fd6df;
    border-radius: 26px;
    padding: 0.8rem;
    width: max-content;
    cursor: pointer;
  }
}

.trackerSidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  height: 100%;
  border-top: 1px solid #e7e7e7;

  .trackerSidebarTop {
    height: calc(100% - 4rem);
    overflow-y: scroll;

    .qrDiv {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 2.5rem;

      & > :nth-child(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 1rem;
          font-weight: 500;
        }
        .option {
          font-size: 0.85rem;
          font-weight: 450;
        }
      }

      & > :nth-child(2) {
        background: #ffffff;
        border: 0.5px solid #e5e5e5;
        border-radius: 15px;
        padding: 1.5rem;
      }
    }
  }

  .btnDiv {
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #343c5b;
    border: 0.5px solid #343c5b;
    border-radius: 25px;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 700;
  }
}

.eachMenuItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 2.5rem;

  .title {
    font-size: 1rem;
    font-weight: 500;
  }
  .valueField {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    border: 0.5px solid #e5e5e5;
    border-radius: 15px;
    padding: 1rem;

    & > div {
      display: flex;
      gap: 10px;
      align-items: center;

      & > img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
    }

    & > img {
      cursor: pointer;
    }
  }
}
