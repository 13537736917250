.tableContainerTrader {
  height: 88%;
  position: relative;
  background-color: #fafafa;
  & > .headings {
    display: flex;
    padding: 10px 3rem;
    height: 45px;
    background-color: #fafafa;
    //   border-bottom: solid 0.5px #e7e7e7;
    border-top: solid 0.5px #e7e7e7;
    font-size: 0.8rem;
    font-weight: 600;

    & > div {
      display: flex;
      align-items: center;
    }

    & > :nth-child(1) {
      width: 35%;
    }
    & > :nth-child(2) {
      width: 28%;
    }
    & > :nth-child(3) {
      width: 28%;
    }
    //   & > :nth-child(4) {
    //     width: 22%;
    //   }
    & > :last-child {
      width: 9%;
      justify-content: center;
    }
  }

  & > .contentTable {
    overflow-y: scroll;
    height: calc(100% - 45px);

    & > div {
      display: flex;
      padding: 10px 3rem;
      /* border-bottom: solid 0.5px #e7e7e7; */
      height: 110px;
      font-size: 1rem;
      font-weight: 500;
      transition: all ease-in 0.1s;
      background: white;
      height: 82px;
      border-radius: 15px;
      margin-top: 0.5rem;

      &:hover {
        background: rgb(231, 231, 231, 0.25);
        // background: rgb(75, 42, 145, 0.1);
        // transform: translateX(10px);
      }

      & > div {
        display: flex;
        align-items: center;
      }

      & > :nth-child(1) {
        width: 35%;
      }
      & > :nth-child(2) {
        width: 28%;
      }
      & > :nth-child(3) {
        width: 28%;
      }
      //   & > :nth-child(4) {
      //     width: 22%;
      //   }

      & > :last-child {
        width: 9%;
        justify-content: center;

        & > div {
     
       
          border: 1px solid #e7e7e7;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.5rem 0;
          width: 100%;
          cursor: pointer;
          transition: all ease-in 0.2s;
          background: #ffffff;
          color: #212529;
          font-weight: 600;
          &:hover {
            background: #ffffff;
            color: #212529;
            font-weight: 600;
          }
        }
      }
    }
  }
}
