.tableContainerAnaltics {
  height: 88%;
  position: relative;
  background-color: #fafafa;
  width: calc(100% - 27rem);
  & > .headings {
    display: flex;
    padding: 10px 3rem;
    height: 45px;
    background-color: #fafafa;
    border-bottom: solid 0.5px #e7e7e7;
    border-top: solid 0.5px #e7e7e7;
    font-size: 0.8rem;
    font-weight: 600;

    & > div {
      display: flex;
      align-items: center;
    }

    & > :nth-child(1) {
      width: 35%;
    }
    & > :nth-child(2) {
      width: 27%;
    }
    & > :nth-child(3) {
      width: 27%;
    }
    //   & > :nth-child(4) {
    //     width: 22%;
    //   }
    & > :last-child {
      width: 11%;
      justify-content: center;
    }
  }

  & > .contentTable {
    overflow-y: scroll;
    height: calc(100% - 45px);

    & > div {
      display: flex;
      padding: 10px 3rem;
      border-bottom: solid 0.5px #e7e7e7;
      height: 110px;
      font-size: 1rem;
      font-weight: 500;
      transition: all ease-in 0.1s;
      background: white;
      height: 82px;
      border-radius: 15px;
      // margin-top: 0.5rem;

      &:hover {
        background: rgb(231, 231, 231, 0.25);
        // background: rgb(75, 42, 145, 0.1);
        // transform: translateX(10px);
      }

      & > div {
        display: flex;
        align-items: center;
      }

      & > :nth-child(1) {
        width: 35%;
      }
      & > :nth-child(2) {
        width: 27%;
      }
      & > :nth-child(3) {
        width: 27%;
      }
      //   & > :nth-child(4) {
      //     width: 22%;
      //   }

      & > :last-child {
        width: 11%;
        justify-content: center;

        & > div {
          border: 1px solid #e7e7e7;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.5rem 0;
          width: 100%;
          cursor: pointer;
          transition: all ease-in 0.2s;
          background: #ffffff;
          color: #212529;
          font-weight: 600;
          &:hover {
            background: #ffffff;
            color: #212529;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.pointBottomCardsAnalysist {
  height: 100% !important;
  display: grid;
  grid-auto-flow: column;
  overflow: scroll;
  width: 100%;
  // gap: 2vw;
  /* margin-right: 2rem; */
  white-space: nowrap;
  justify-content: flex-start;
  border: 0.5px solid #ebebeb;
  position: relative;
  .sectionBottomPoits {
    width: 100%;
  }
  .numberCard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pointsCards {
    height: 403px;
    width: 27rem;
    padding: 1.5vw;
    align-items: center;

    background: #ffffff;

    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:hover {
    //   transform: translateY(-5px);
    //   transition: all 0.3s ease-in-out;
    }
    .time-points {
      height: 33px;
      justify-content: center;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      width: 100%;
      background: rgba(75, 42, 145, 0.1);
      border-radius: 25px;
      svg {
        margin-right: 10px;
      }
    }
    .analytis {
      height: 51px;
      width: 92%;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      /* or 119% */

      display: flex;
      align-items: center;
      text-align: center;

      position: absolute;
      bottom: 2rem;
      color: #ffffff;
      margin-top: 3vh;
      justify-content: center;
      background: #4b2a91;
      border-radius: 25px;
      cursor: pointer;
      &:hover {
        transform: translateY(-5px);
      }
    }
    .botteMpoinumberCardntDetial {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 2.5vh;
    }
    h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      color: #212529;
    }
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      text-align: right;
      margin-bottom: 0;
      color: #212529;
    }
    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 35px !important;
      line-height: 31px !important;
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 2.4vh;
      color: #212529;
    }
    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;

      color: #212529;
      margin-bottom: 0;
    }
  }
}


.handlechangeAnlitys{
    display: flex;
    justify-content: space-between;
    height: calc(100% - 123px);
}