.content-section {
  position: relative;
  .assetPlatformTable {
    border: 1px solid #e7e7e7;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);
    flex: 0 0 53%;
    max-height: 53%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    &.true {
      max-height: unset;
      flex: 1;
      margin-bottom: 0;
    }
    .assetTableControlls {
      height: 50px;
      border-bottom: 1px solid #e7e7e7;
      display: flex;
      .bt-asset {
        padding: 0 50px;
        cursor: pointer;
        border-right: 1px solid #e7e7e7;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 170px;
        font-size: 16px;
        color: $primary-color;
        opacity: 0.7;
        .platform-select {
          display: none;
          position: relative;
          padding: 5px;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #e7e7e7;
          min-width: 170px;
          margin-left: 20px;
          font-weight: normal;
          .btn-togle {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 600;
            img {
              height: 16px;
              width: 16px;
              margin-right: 8px;
            }
          }
          .platform {
            font-size: 12px;
            margin-left: auto;
            margin-right: 4px;
          }
          .menu {
            border: 1px solid #e7e7e7;
            z-index: 222;
            position: absolute;
            top: 100%;
            left: -1px;
            right: -1px;
            .btn-togle {
              background-color: white;
              border-bottom: 1px solid #e7e7e7;
              padding: 5px;
              display: flex;
              align-items: center;
              font-size: 11px;
              img {
                height: 14px;
                width: 14px;
                margin-right: 12px;
              }
              .platform {
                font-size: 11px;
                margin-left: auto;
                margin-right: 14px;
              }
              &:last-child {
                border: none;
              }
              &:hover {
                background-color: rgb(246, 246, 246);
              }
            }
          }
        }
        img {
          height: 18px;
          width: auto;
        }
        &:hover {
          opacity: 1;
          transform: scale(1.05);
        }
        &.true {
          opacity: 1;
          font-weight: bold;
          .platform-select {
            display: flex;
          }
        }
      }
      .searchWrapper {
        margin: 0 0 0 auto;
        padding: 0 2%;
        display: flex;
        align-items: center;
        position: relative;
        input {
          min-width: 220px;
          border: none;
          background: none;
        }
        .menu {
          border: 1px solid #e7e7e7;
          z-index: 222;
          position: absolute;
          top: 100%;
          left: -1px;
          right: -1px;
          .btn-togle {
            background-color: white;
            border-bottom: 1px solid #e7e7e7;
            padding: 5px;
            display: flex;
            align-items: center;
            font-size: 13px;
            img {
              height: 14px;
              width: 14px;
              margin-right: 12px;
            }
            .platform {
              font-size: 13px;
              margin-left: auto;
              margin-right: 14px;
            }
            &:last-child {
              border: none;
            }
            &:hover {
              background-color: rgb(246, 246, 246);
            }
          }
        }
      }
      .coinsSelect {
        margin-left: auto;
        display: flex;
        height: 100%;
        align-items: center;
        margin-right: 20px;
        .coinBtn {
          height: 35px;
          width: 35px;
          margin: 0 5px;
          border: 1px solid $vault-border-color;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 60%;
          }
          &.true {
            background: $primary-color;
            img {
              filter: brightness(0) invert(1);
            }
          }
        }
        .searchBtn {
          position: relative;
          height: 35px;
          min-width: 35px;
          margin: 0 5px;
          border: 1px solid $vault-border-color;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          .btnS {
            height: 35px;
            width: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          input {
            display: none;
            border: none;
            background: none;
            font-size: 14px;
            padding: 0 15px;
            width: 150px;
          }
          .menu {
            border: 1px solid #e7e7e7;
            z-index: 222;
            position: absolute;
            top: 100%;
            left: -1px;
            right: -1px;
            .btn-togle {
              background-color: white;
              border-bottom: 1px solid #e7e7e7;
              padding: 5px;
              display: flex;
              align-items: center;
              font-size: 11px;
              img {
                height: 14px;
                width: 14px;
                margin-right: 12px;
              }
              .platform {
                font-size: 11px;
                margin-left: auto;
                margin-right: 14px;
              }
              &:last-child {
                border: none;
              }
              &:hover {
                background-color: rgb(246, 246, 246);
              }
            }
          }
          &.true {
            flex: 1;
            input {
              display: block;
            }
          }
        }
      }
      .fullIcon {
        height: 17px;
        margin: auto 50px auto 0;
      }
    }
    .onlyFiat {
      padding: 0 20px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #e7e7e7;
      span {
        font-size: 11px;
        font-weight: bold;
      }
      .switch {
        cursor: pointer;
        height: 20px;
        width: 40px;
        border-radius: 9px;
        border: 1px $primary-color solid;
        margin: 0 6px;
        padding: 2px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .switchControll {
          height: 14px;
          width: 14px;
          border-radius: 7px;
          background: $primary-color;
        }
        &.true {
          justify-content: flex-start;
        }
      }
    }
    .tableScrollWrapper {
      .asetPlatformTable {
        table-layout: fixed;
        position: relative;
        width: 100%;
        .tableHead {
          tr {
            th {
              z-index: 1;
              top: 0;
              position: sticky;
              background: rgb(251, 251, 251);
              font-size: 12px;
              padding: 6px 0;
              &:first-child {
                padding-left: 50px;
              }
              &:last-child {
                text-align: center;
              }
            }
          }
        }
        .tableContent {
          tr {
            height: 100px;
            border-bottom: 1px solid #e7e7e7;
            cursor: pointer;
            td {
              font-size: 16px;
              font-weight: 600;
              padding: 36px 0;
              padding-right: 20px;
              &.coin {
                .coin-name {
                  display: flex;
                  align-items: center;
                  .coinLogo {
                    height: 20px;
                    width: 20px;
                    margin-right: 6px;
                  }
                }
              }
              &.annRate {
              }
              &.dayChange {
                color: $primary-green;
                &.true {
                  color: #f13219;
                }
              }
              &.supply {
              }
              &.chart {
                height: 100px;
                width: 280px;
                position: relative;
                .chartIn {
                  position: absolute;
                  top: 3px;
                  bottom: 3px;
                  left: 6px;
                  right: 6px;
                  svg {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
              &.estimatedDays {
                padding: 0;
                width: 220px;
                max-width: 220px;
                .estimatedDaysIn {
                  height: 45px;
                  display: flex;
                  border: 1px solid $vault-border-color;
                  margin-right: 50px;
                  input {
                    padding: 0 15px;
                    width: 0;
                    flex-grow: 1;
                    border: none;
                    background: none;
                    text-align: center;
                    -moz-appearance: textfield;
                  }
                  .btn-go {
                    height: 100%;
                    width: 45px;
                    display: flex;
                    border-left: 1px solid $vault-border-color;
                    justify-content: center;
                    align-items: center;
                    color: $primary-color;
                  }
                  input::-webkit-outer-spin-button,
                  input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }
                }
              }
              &:first-child {
                padding-left: 50px;
              }
            }
            &.true {
              border-bottom: none;
            }
            &:hover {
              background-color: whitesmoke;
            }
          }
          .detailRow {
            position: relative;
            cursor: auto;
            td {
              background: white;
              width: 100%;
              .bondDetail {
                height: 100%;
                width: 100%;
                padding: 2% 50px;
                display: flex;
                flex-direction: column;
                .cardRow {
                  height: 110px;
                  display: flex;
                  justify-content: space-between;
                  .bondCard {
                    border: solid 1px $vault-border-color;
                    flex: 0 0 21.5%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0 3%;
                    .value {
                      color: $primary-green;
                      font-size: 30px;
                      line-height: 1;
                      small {
                        font-size: 18px;
                        color: $primary-color;
                      }
                    }
                    .label {
                      font-size: 15px;
                      color: $primary-color;
                      margin: 0;
                    }
                  }
                }
                .controllsRow {
                  margin-top: 2%;
                  height: 40px;
                  line-height: 1;
                  display: flex;
                  align-items: center;
                  font-size: 16px;
                  .switchCurrency {
                    cursor: pointer;
                    text-decoration: underline;
                  }
                  .btnSimulation,
                  .btnBond {
                    cursor: pointer;
                    height: 100%;
                    width: 180px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                  .btnSimulation {
                    margin-left: auto;
                    border: solid 1px $primary-color;
                    color: $primary-color;
                  }
                  .btnBond {
                    margin-left: 20px;
                    background: $primary-color;
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
      .cover {
        table-layout: fixed;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 40%;
        }
      }
    }
    .tableCoinDetail {
      flex: 1;
      padding: 20px 30px;
      display: flex;
      flex-direction: column;
      .breadCrumbs {
        padding-left: 20px;
        display: flex;
        .div,
        .bread {
          margin-right: 10px;
          line-height: 1;
          font-size: 12px;
          font-weight: 500;
        }
        .bread {
          cursor: pointer;
          &:last-child,
          &:hover {
            font-weight: 700;
          }
        }
        color: $primary-color;
      }
      .coinDetail {
        display: flex;
        align-items: center;
        padding: 25px 20px;
        img {
          height: 30px;
        }
        .coinName {
          font-size: 28px;
          font-weight: bold;
          margin-left: 10px;
          color: $primary-color;
        }
        .btnDeposit,
        .btnBuy {
          cursor: pointer;
          height: 90%;
          width: 140px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 14px;
        }
        .btnDeposit {
          background: $primary-color;
          color: white;
          margin-left: auto;
        }
        .btnBuy {
          border: $primary-color 1px solid;
          color: $primary-color;
          margin-left: 15px;
        }
      }
      .lastPrice,
      .lastIntrest {
        cursor: pointer;
        flex: 1;
        border: 1px solid #e7e7e7;
        max-height: 35%;
        padding: 0 50px;
        display: flex;
        align-items: center;
        .price {
          flex: 0 0 40%;
          max-width: 40%;
          h2 {
            font-size: 27px;
            margin: 0;
            color: $primary-color;
            small {
              margin-left: 6px;
              font-size: 15px;
              color: $primary-green;
              &.true {
                color: $primary-red;
              }
            }
          }
          .label {
            font-size: 12px;
            color: $primary-color;
          }
        }
        .subSec {
          flex: 0 0 20%;
          max-width: 20%;
          h3 {
            font-size: 24px;
            margin: 0;
            color: $primary-color;
          }
          .label {
            font-size: 12px;
            color: $primary-color;
          }
        }
        &:hover {
          box-shadow: inset 0 0 5px #00000040;
        }
      }
      .lastPrice {
        margin-bottom: 15px;
      }
    }

    &.fullScreen {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: white;
      z-index: 2;
    }
  }
  .analyticsEarn {
    border: 1px solid #e7e7e7;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);
    height: 0;
    flex-grow: 1;
    display: flex;
    .menu {
      flex: 0 0 25%;
      border-right: 1px solid #e7e7e7;
      position: relative;
      .view {
        padding: 20px 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .menu-itm {
          flex: 0 0 20%;
          cursor: pointer;
          color: #212529;
          font-size: 16px;
          margin-left: 10px;
          opacity: 0.5;
          &.true {
            font-size: 22px;
            font-weight: bold;
            margin: 0;
            opacity: 1;
          }
        }
      }
      &.true {
        zoom: 0.9;
      }
    }
    .content {
      display: flex;
      .textContent {
        flex: 1;
        padding: 50px;
        h6 {
          font-size: 22px;
          font-weight: bold;
        }
        .textDetail {
          font-size: 18px;
        }
      }
      .palyerWrapper {
        background: $primary-color;
        min-width: 320px;
        flex: 0 0;
        border-left: 1px solid $vault-border-color;
        .react-player__preview {
          background-size: contain !important;
          background-repeat: no-repeat !important;
        }
      }
    }
  }
  @include mdportrait {
    .assetPlatformTable {
      flex: unset;
      max-height: unset;
      .assetTableControlls {
        height: unset;
        flex-wrap: wrap;
        .bt-asset {
          padding: 0 20px;
          width: unset;
          min-width: 120px;
          font-size: 12px;
          flex: 0 0 50%;
          border-bottom: 1px solid #e7e7e7;
          height: 40px;
          order: 0;
          &.true {
            &.platform,
            &.bond {
              .platform-select {
                display: none;
              }
              // border-top: 1px solid #e7e7e7;
              // flex: 0 0 100%;
              // order: 1;
            }
          }
        }
        .searchWrapper {
          display: none;
        }
        .fullIcon {
          display: none;
        }
        .coinsSelect {
          order: 2;
          height: 50px;
          margin: 0 10px;
          flex: 1;
          .searchBtn {
            input {
              flex: 1;
            }
          }
        }
      }
      .tableScrollWrapper {
        .asetPlatformTable {
          &.asset {
            th {
              background: $primary-color !important;
              color: white;
            }
            .col-a,
            .col-d,
            .col-e,
            .col-f {
              display: none !important;
            }
            .col-b {
              padding-left: 35px;
            }
            .col-c {
              padding-right: 35px;
              text-align: right;
            }
          }
          &.platform {
            th {
              background: $primary-color !important;
              color: white;
            }
            .col-a,
            .col-c,
            .col-e,
            .col-f,
            .col-g {
              display: none !important;
            }
            .col-b {
              padding-left: 35px;
            }
            .col-d {
              padding-right: 35px;
              text-align: right;
            }
          }
          &.bonds {
            th {
              background: $primary-color !important;
              color: white;
            }
          }
        }
        &.bonds {
          > div {
            position: absolute !important;
          }
          .asetPlatformTable {
            width: 240%;
          }
        }
      }
      .tableCoinDetail {
        flex: unset;
        .breadCrumbs {
          padding: 0;
          align-items: center;
        }
        .coinDetail {
          justify-content: space-between;
          flex-wrap: wrap;
          img {
            flex: 0 0 15%;
            object-fit: contain;
          }
          .coinName {
            flex: 0 0 82%;
            margin-left: 3%;
          }
          .btnDeposit,
          .btnBuy {
            flex: 0 0 45%;
            height: 30px;
            margin: 9px 0 0 0;
          }
        }
        .lastPrice,
        .lastIntrest {
          flex-direction: column;
          padding: 20px 0;
          .price,
          .subSec {
            flex: unset;
            max-width: unset;
            margin-bottom: 20px;
            h2,
            h3,
            .label {
              text-align: center;
            }
          }
          .subSec {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      &.false {
        .fullIcon {
          display: none;
        }
      }
    }
    .analyticsEarn {
      flex-direction: column;
      height: auto;
      .menu {
        .view {
          position: relative !important;
          padding: 50px;
          padding-bottom: 0;
        }
      }
      .content {
        flex-direction: column;
        .palyerWrapper {
          width: 100% !important;
          height: 200px;
          min-width: unset;
          min-height: 200px;
        }
      }
    }
  }
}
