.mainGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'left right';
  height: 100vh;
  width: 100vw;
  // background-color: #f5f5f5;
  // background-color: red;
}
.leftSide {
  // background-color: red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rightSide {
  // background-color: pink;
  // padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.input1 {
  width: 50%;
  height: 7%;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 2%;
  padding-left: 23px;
  font-size: 1.9vh;
  &::placeholder {
    font-size: 1.9vh;
  }
  // width: 377px;
}
.input2 {
  width: 50%;
  height: 7%;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 2%;
  // width: 377px;
  font-size: 1.9vh;
  &::placeholder {
    font-size: 1.9vh;
  }
}
.forgotPassword {
  // background: linear-gradient(90deg, #3D4394 0%, #81CBD7 91.36%);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // background-clip: text;
  // text-fill-color: transparent;
  font-weight: 400;
  // size: 16px;
  font-size: 1.7vh;
  width: 50%;
  text-align: left;
  cursor: pointer;
  padding-bottom: 3%;
  // padding-left: 1%;
}

.loginButton {
  background: #4b2a91;
  // width: 50%;
  padding: 3%;
  color: white;
  font-weight: 700;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8%;
  cursor: pointer;
  font-size: 1.9vh;
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
  }
}
.logoutButton {
  background: #dc3027;
  // width: 50%;
  padding: 3%;
  color: white;
  font-weight: 700;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4%;
  cursor: pointer;
  font-size: 1.9vh;
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
  }
}
.loginButton2 {
  background: #4b2a91;
  // width: 50%;
  padding: 3%;
  color: white;
  font-weight: 700;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4%;
  cursor: pointer;
  font-size: 1.9vh;
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
  }
}

.loginButtonDisabled {
  background: #4b2a91;
  width: 50%;
  padding: 2%;
  color: white;
  font-weight: 700;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 85px;
  cursor: pointer;
  opacity: 0.5;
  font-size: 1.9vh;
}

.googleLogin {
  width: 50%;
  padding: 2%;
  font-weight: 600;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #212529;
  font-size: 1.9vh;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 50%;
  height: 100vh;
  padding: 0px 20%;
  // background-color: red;
}

.formContainer1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 50%;
  height: 100vh;
  // padding: 0px 20%;
  // background-color: red;
}
.step3Grid {
  display: flex;
  width: 50%;
  justify-content: space-between;
  gap: 3vw;
  div {
    width: 100%;
  }
}
.dataLabel {
  font-size: 1.4vh;
  font-weight: 400;
  color: #212529;
  margin-bottom: 2%;
}
.dataValue {
  padding: 20px 0px;
  padding-left: 23px;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  margin-bottom: 10%;
  font-size: 1.6vh;
  font-weight: 500;
}

.loadingComponent {
  position: absolute;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: black;
  opacity: 0.4;
}
.logo {
  width: 50%;
}
.logo1 {
  width: 30%;
}
.buttonWrapper {
  width: 50%;
}
.buttonWrapper3 {
  width: 50%;
  display: flex;
  justify-content: space-between;
  gap: 3vw;
  div {
    width: 100%;
  }
}
.noRegistration {
  font-weight: 400;
  padding-bottom: 5%;
  padding-top: 5%;
  width: 50%;
  text-align: center;
}

.container {
  position: relative;
  transition: bottom 0.3s ease-in-out;
}
// break point for mobile

@media only screen and (max-width: 600px) {
  .logo {
    width: 100%;
    // height: 100%;
    // margin: 5%;
    position: absolute;
    top: 0;
    border-bottom: 1px solid #e5e5e5;
    padding: 5% 20%;
    background-color: white;
  }
  .logo1 {
    width: 100%;
    // height: 100%;
    // margin: 5%;
    position: absolute;
    top: 0;
    border-bottom: 1px solid #e5e5e5;
    padding: 5% 20%;
    background-color: white;
  }

  .formContainer {
    padding: 0px 10%;
    justify-content: flex-start;
    margin-top: 30vh;
  }
  .input1 {
    width: 100%;
  }
  .input2 {
    width: 100%;
  }
  .forgotPassword {
    width: 100%;
    // text-align: center;
  }
  .buttonWrapper {
    // position: absolute;
    bottom: 5%;
    width: 100%;
    padding: 0px 0px;
  }
  .loginButton {
    width: 100%;
    height: 60px;
  }
  .logoutButton {
    width: 100%;
    height: 60px;
  }
  .loginButton2 {
    width: 100%;
    height: 60px;
  }
  .loginButtonDisabled {
    width: 100%;
  }
  .googleLogin {
    width: 100%;
  }
  .step3Grid {
    width: 80%;
    flex-direction: column;
    // overflow-y: scroll;
    height: 100vh;
    margin-top: 30vh;
    div {
      width: 100%;
    }
  }
  .formContainer1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 50%;
    height: 100vh;
    // padding: 0px 20%;
    // background-color: red;
    overflow-y: scroll;
  }
  .buttonWrapper3 {
    // position: absolute;
    // bottom: 5%;
    width: 80%;
    padding: 0px 0px;
    // width: 100%;
    flex-direction: column;
    margin-bottom: 5vh;
    div {
      width: 100%;
    }
  }
  .noRegistration {
    font-weight: 400;
    padding-bottom: 5%;
    padding-top: 5%;
    width: 100%;
    text-align: center;
  }
}

.cornerButton {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1%;
  margin: 1%;
  cursor: pointer;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  &:hover {
    box-shadow: 0px 0px 10px 0px #e5e5e5;
    // transform: scale(0.8);
    // transition: all 0.3s ease-in-out;
  }
}
.copyOptions {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 6%;
  margin-right: 1%;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  width: 220px;

  :nth-child(1) {
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid #e5e5e5;
    font-weight: 600;
    &:hover {
      background: #4b2a91;
      color: white;
      font-weight: 600;
      border-radius: 10px 10px 0px 0px;
    }
  }
  :nth-child(2) {
    padding: 20px;
    cursor: pointer;

    font-weight: 600;
    &:hover {
      background: #4b2a91;
      color: white;
      font-weight: 600;
      border-radius: 0px 0px 10px 10px;
    }
  }
}
