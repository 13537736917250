@import "../../../static/scss/mixin";

.cards {
  margin-top: 25px;
  padding: 20px;
  border: 1px solid #e7e7e7;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
}

.first-column {
  display: flex;
  flex-direction: row;
  .child-skeleton {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  .child-div {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    width: 200px;
  }
}
.second-column {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  width: 100px;
}
.third-skeleton {
  margin-left: 5px;
  height: 40px;
  width: 120px;
}
