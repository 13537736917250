.ads {
  position: relative;
  display: flex;
  width: 100%;
  padding-bottom: 9rem;
  // display: flex;
  flex-wrap: wrap;
  overflow: scroll;

  height: 100%;

  // base
  .flip {
    cursor: pointer;
    position: relative;
    > .front,
    > .back {
      display: block;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition-duration: 0.5s;
      transition-property: transform, opacity;
    }
    > .front {
      transform: rotateY(0deg);
    }
    > .back {
      position: absolute;
      opacity: 0;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      transform: rotateY(-180deg);
    }
    &:hover {
      > .front {
        transform: rotateY(180deg);
        display: none;
      }
      > .back {
        opacity: 1;
        transform: rotateY(0deg);
      }
    }
    &.flip-vertical {
      > .back {
        transform: rotateX(-180deg);
      }
      &:hover {
        > .front {
          transform: rotateX(180deg);
        }
        > .back {
          transform: rotateX(0deg);
        }
      }
    }
  }

  // custom
  .flip {
    position: relative;
    display: inline-block;
    width: 33.333%;
    height: 231px;
    border: 1px solid #ebebeb;
    border-top: 0px solid;
    border-left: 0px solid;

    img {
      height: 57.56px;
    }
    > .front {
      display: block;

      color: white;
      width: inherit;
      background-size: cover !important;
      background-position: center !important;
      width: 100%;
      height: 100%;

      // background: #ffff;

      display: flex;
      align-items: center;
      justify-content: center;
    }
    > .back {
      display: block;
      padding-top: 2rem;
      color: white;
      width: inherit;
      background-size: cover !important;
      background-position: center !important;
      width: 100%;
      height: 100%;

      //   background: #ffff;

      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;

      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
    

        color: #212529;
      }
    }
  }
}

.innerAdsDash {
  height: 100vh;
  overflow: scroll;

  .headerDash {
    padding: 2rem;
    padding-top: 0;
    padding-bottom: 0;
    padding: 0px 100px;
  }
  .contnetaddtop {
    padding: 0px 100px;
    height: 100%;
    padding-top: 0;
    overflow: scroll;
    height:auto;
    position: relative;
    top: -2.4rem;
    padding-top: 3px;
  }
  .cardDash {
    display: flex;
    align-items: center;

    flex-wrap: wrap;
    gap: 2%;

    .lefsiteadd {
      background: #ffffff;
      border: 0.5px solid #e7e7e7;
      border-radius: 15px;
      width: 32%;
      padding: 2rem;
      margin-bottom: 1rem;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        .blacktinit {
          display: flex;
        }
      }
    }
    .rightsiteadd {
      background: #ffffff;
      border: 0.5px solid #e7e7e7;
      border-radius: 15px;
      width: 49%;
      padding: 2rem;
      padding: 2rem;
      // height: 54rem;
      height: 62.7rem;
    }
    .labelSection {
      display: flex;
      align-items: center;
      margin-top: 1.4rem;
      justify-content: space-between;
      .payout {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;

        color: #212529;
        width: 170px;
        height: 42px;

        background: rgba(237, 237, 237, 0.45);
        border: 0.5px solid #e7e7e7;
        border-radius: 25px;
        margin-right: 10px;
        justify-content: center;
        // &:hover {
        //   transform: scale(1.1);
        // }
      }
      .payoutLeft {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;

        color: #ffffff;
        width: 170px;
        height: 42px;

        background: rgba(237, 237, 237, 0.45);
        border: 0.5px solid #e7e7e7;
        border-radius: 25px;
        justify-content: center;
        // &:hover {
        //   transform: scale(1.1);
        // }
      }
    }
    .borderCard {
      width: 100%;
      // height: 300px;
      left: 847px;
      top: 526px;

      margin-bottom: 0.5rem;
      background-size: 100% 100% !important;
      background-repeat: no-repeat !important;
      background: #ffffff;
      border: 0.5px solid #e7e7e7;
      border-radius: 15px;
      width: 100%;
      height: 227.3px;
      .blacktinit {
        font-style: normal;
        font-weight: 600;
        height: 100%;
        width: 100%;
        font-size: 20px;
        line-height: 26px;
        /* or 130% */

        display: none;
        align-items: center;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        background-color: #000000cf;

        color: #ffffff;
        svg {
          margin-bottom: 1.2rem;
        }
      }
    }
    .innerContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
    }
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      display: flex;
      align-items: center;

      color: #212529;
      margin-bottom: 0;
    }
    h5 {
      font-style: normal;
      font-weight: 400;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;

      color: #212529;
    }
  }
  .sticky {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-bottom: 0.5px solid #e7e7e7;
    left: 0;
    position: fixed;
    height: 9rem;
    padding: 0rem 5rem;

    top: 156px;
    z-index: 9;
    width: 100%;
  }
  // position:stickyClass ?  "fixed" :"relative",
  // top: stickyClass ? "0px" :"-4.3rem;",
  // width:stickyClass &&  "100%",
  // left:stickyClass &&  "0",
  // borderTop:stickyClass &&  "0px",
  // borderRadius: stickyClass && "0px",
  .topheaderadd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    border-radius: 15px;
    position: relative;
    position: relative;
    height: 9rem;
    padding: 0rem 5rem;
    top: -4.3rem;
  }
  .cardAdds {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      height: 55px;
    }
    // width: 20%;
  }
  .website {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    /* or 127% */

    display: flex;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #212529;
    width: 156px;
    height: 41.25px;
    background: rgba(237, 237, 237, 0.5);
    border-radius: 35px;
    &:hover {
      scale: 1.01;
    }
  }
  .signup {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    /* or 127% */
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
    width: 156px;
    height: 41.25px;

    background: #063765;
    border-radius: 35px;
    &:hover {
      transform: scale(1.1);
    }
  }
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 43px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #212529;
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    /* or 167% */
    height: 4rem;
    display: flex;
    align-items: center;

    color: #212529;
  }
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #212529;
    margin-bottom: 0;
  }
  .imageSectionadds {
    padding-bottom: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20rem;
    position: relative;
    background-color: #063765;
    img {
      height: 98px;
    }
    .allbrands {
      position: absolute;
      top: 2rem;
      left: 100px;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;

      color: #ffffff;
      &:hover {
        transform: scale(1.1);
      }
      img {
        height: 10px;
        margin-right: 8px;
      }
    }
  }
}

.videopageSection {
  .leftsideSectiom {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin-top: 2rem;
    padding: 0px 100px;
    color: #212529;
    cursor: pointer;
    &:hover {
      scale: 1.01;
    }
  }
  .playSection {
    width: 100%;
    max-width: 674px;
    text-align: center;
    margin: auto;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    /* or 167% */


    text-align: center;

    color: #212529;
    margin-bottom: 3rem;
  }
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 45px;

    text-align: center;
    margin-bottom: 1.7rem;
    color: #212529;
    margin-top: 2rem;
  }
  svg {
    margin-right: 10px;
  }
  .videoCard {
    width: 100%;
    height: 348px;

    background: rgba(0, 0, 0, 0.54);
    border-radius: 25px;
  }
  .react-player {
    border-radius: 25px;
  }
  .topHeaderPlayOut {
    width: 100%;
    max-width: 674px;

    margin: auto;
    padding-bottom: 5rem;
    margin-top: 5rem;
    .deatilPlayput {
      display: flex;
      justify-content: space-between;
      margin-top: 0.7rem;
    }
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 45px;

      color: #212529;
    }
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 45px;
      margin-bottom: 6rem;
      color: #212529;
    }
    .buttonClick{
      display: none;
    }
    .SeachSectionFlied{
    position: relative;
    width: 100%;
    }
    input {
      width: 100%;
      height: 110px;
      text-align: center;
      margin-top: 3rem;

      background: #ffffff;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 30px;
      /* or 100% */

      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #212529;

      opacity: 0.25;
      border: 1px solid #e7e7e7;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 25px;
    }
    label {
      max-width: 474px;
      width: 100%;
      margin: auto;
      height: 92px;
      font-weight: 600;
      font-size: 35px;
      line-height: 19px;
      justify-content: center;

      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
      background: #063765;
      border-radius: 35px;
      margin-top: 3rem;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 700px) {

  .videopageSection .topHeaderPlayOut input {
    width: 100%;
    height: 63px;
    text-align: center;
    margin-top: 1rem;
    background: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 12px;
    /* justify-content: center; */
    color: #212529;
    opacity: 0.25;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
}
.videopageSection .topHeaderPlayOut label{
  display: none;
}
  .videopageSection .topHeaderPlayOut .buttonClick {
    width: 83px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    background: #063765;
    width: 83px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    background: #063765;
    position: absolute;
    top: 7px;
    right: 17px;
  }
  .ads .flip img {
    height: 43.56px;
  }
  .videopageSection .leftsideSectiom {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin-top: 1rem;
    padding: 0px 7%;
    color: #212529;
    cursor: pointer;
  }
  .videopageSection .topHeaderPlayOut {
    padding: 0px 7%;
    padding-bottom: 4rem;
  }
  .videopageSection .topHeaderPlayOut h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 2rem;
  }
  .videopageSection .playSection {
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin: auto;
    padding: 0px 7%;
  }
  .innerAdsDash .headerDash {
    padding: 0px 7%;
  }
  .innerAdsDash .contnetaddtop {
    padding: 0px 7%;
  }
  .innerAdsDash .cardDash .labelSection {
    margin-top: 1.4rem;
  }
  .innerAdsDash .cardDash .lefsiteadd {
    background: #ffffff;
    border: 0px solid #e7e7e7;
    border-radius: 15px;
    width: 100%;
    padding: 0rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  .innerAdsDash .cardAdds {
    width: 50%;
    margin-bottom: 1.5rem;
    &:nth-child(5) {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 1rem;
    }
  }
  .innerAdsDash .topheaderadd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border: 0px solid #e7e7e7;
    border-radius: 15px;
    position: relative;
    position: relative;
    height: auto;
    padding: 1.4rem 0rem;
    top: 0;
    flex-wrap: wrap;
  }

  .ads .flip {
    width: 100%;
  }
  .innerAdsDash .imageSectionadds img {
    height: 58px;
  }
  .innerAdsDash .imageSectionadds {
    padding-bottom: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 13rem;
  }
  .innerAdsDash .imageSectionadds .allbrands {
    position: absolute;
    top: 2rem;
    left: 7%;
  }
}



.drawerAds{
  position: absolute;
  /* top: 0; */
  width: 40%;
  height: calc(100% - 112px);
  background-color: white;
  z-index: 99;
  right: 0;
  border-left: 0.5px solid #E7E7E7;
  padding: 3rem;
  bottom: 0;
  .submit{
    font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;

color: #FFFFFF;width: 100%;
height: 68px;
margin-bottom: 1rem;
justify-content: center;
background: #4B2A91;
border-radius: 10px;
&:hover{
  transform: scale(1.1);
}
  }
.labelbottom{
  position: absolute;
  bottom: 1rem;
  left: 2rem;
  width: calc(100% - 4rem)
}
  .back{
    font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
text-align: center;
margin-bottom: 0;
color: #FFFFFF;width: 100%;
height: 68px;
justify-content: center;

background: #212224;
border-radius: 10px;
&:hover{
  transform: scale(1.1);
}
  }

  input{
    width: 100%;
height: 83px;
padding-left: 1rem;font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;
display: flex;
align-items: center;

color: #212224;

// opacity: 0.25;

background: #FFFFFF;
border: 0.5px solid #E7E7E7;
border-radius: 10px;
  }
.leftcardlistdrawer
  {
    width: 100%;
height: 83px;

padding-left: 1rem;
padding-right: 1rem;
display: flex;
align-items: center;
justify-content: space-between;
background: #FFFFFF;
border: 0.5px solid #E7E7E7;
border-radius: 10px;
margin-bottom: 1rem;
cursor: pointer;
&:hover{
  transform: scale(1.1);
}
.textinside{
  font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 18px;
display: flex;
align-items: center;

color: #212224;

}
  }
  h1{
    font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 29px;
display: flex;
align-items: center;

color: #212224;
  }
  .topHeaderDrawerAds{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
    img{
      &:hover{
  transform: scale(1.1);
      }
    
    }
  }
}