.chain-view-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #212529;
  position: relative;
  z-index: 1;
  height: 88%;
  overflow-y: scroll;
  border-top: 0.5px solid #e5e5e5;

  .search-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: white;
    z-index: 10;
    display: flex;
    flex-direction: row;
  }
  .header-container {
    display: flex;
    align-items: center;
    padding: 30px 40px;
    height: 110px;
    .profile-container {
      display: flex;
      flex: 1;
      align-items: center;
      .profile-avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .name-container {
        margin-left: 20px;
        .profile-name {
          font-weight: 600;
          font-size: 1.3rem;
        }
        .profile-email {
          font-size: 0.7rem;
        }
      }
    }
    .actions-container {
      display: flex;
      .action-item {
        border: 1px solid #e7e7e7;
        padding: 10px;
        cursor: pointer;
        margin-left: 10px;
        &.active {
          border-color: #08152d;
        }
        .action-icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .chain-view-list {
    height: calc(100% - 140px);
    flex-grow: 1;
    // overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .chain-view-header {
      display: flex;
      border-top: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;
      .chain-view-level {
        width: 130px;
        font-weight: 600;
        border-right: 1px solid #e7e7e7;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
      }
      .chian-view-title {
        flex: 1;
        font-weight: 600;
        padding: 0 30px;
        display: flex;
        align-items: center;
      }
      .action-item {
        border-left: 1px solid #e7e7e7;
        padding: 18px 20px;
        cursor: pointer;
        .action-icon {
          height: 20px;
          width: 20px;
        }
      }
    }

    .level-user-list {
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 40px 5px;
      width: 100%;
      .user-list-container {
        display: flex;
        align-items: center;
        width: 98%;
        height: 120px;
        .scroll-arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #e7e7e7;
          height: 40px;
          width: 40px;
          cursor: pointer;
          margin: 0 10px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      .level-user-empty {
        text-align: center;
        font-weight: 600;
        font-size: 1.3rem;
      }
      .list {
        display: flex;
        width: 100%;
        height: 100%;
        overflow-x: auto;
        scroll-behavior: smooth;
        &::-webkit-scrollbar {
          height: 0;
        }
        .user-item {
          display: flex;
          align-items: center;
          margin-right: 20px;
          border: 1px solid #e7e7e7;
          padding: 15px 20px;
          position: relative;
          z-index: 1;
          cursor: pointer;
          &.active {
            border: 1px solid #08152d;
          }
          &.disabled {
            cursor: not-allowed;
          }
          .name-container {
            width: 160px;
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            .name {
              font-weight: 600;
              font-size: 1.2rem;
              max-lines: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .email {
              font-size: 0.7rem;
              max-lines: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .earnings-button {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px;
            .earnings-icon {
              position: relative;
              width: 25px;
              height: 25px;
              z-index: 2;
            }
          }
        }
      }
    }
  }
}

.profile-avatar {
  border-radius: 50%;
  cursor: pointer;
}

.profile-avatar-placeholder {
  border-radius: 50%;
  background-color: #08152d;
  color: white;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.earnings-modal {
  color: #212529;
  .earning-modal-header {
    display: flex;
    .header-item {
      flex: 1;
      text-align: center;
      background-color: #08152d;
      color: white;
      font-weight: 600;
      padding: 15px 0;
      &.alt {
        background-color: white;
        color: #212529;
        opacity: 0.5;
      }
    }
  }
  .tab-container {
    display: flex;
    border-bottom: 0.25px solid #08152d;
    border-top: 0.25px solid #08152d;
    .tab-item {
      text-transform: capitalize;
      flex: 1;
      text-align: center;
      padding: 10px;
      font-size: 0.75rem;
      &.active {
        border-bottom: 1px solid #08152d;
        font-weight: 600;
      }
    }
  }
  .view-container {
    height: 400px;
    display: flex;
    flex-direction: column;
    .earnings {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
      padding: 30px;
      .earning-item {
        text-align: center;
        .earning-title {
          font-weight: 600;
          font-size: 1.4rem;
        }
        .earning-label {
          text-transform: capitalize;
        }
      }
    }
  }
}
