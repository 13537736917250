.addAccountContainer {
  // background-color: red;
  height: 97vh;
  padding: 0px 40px;
}
.stepTitle {
  font-size: 14px;
  font-weight: 700;
  padding-top: 50px;
  padding-bottom: 15px;
}
.selectionWrapper {
  border: 0.5px solid #e5e5e5;
  border-bottom: 0px;
  border-radius: 10px 10px 0px 0px;
  height: 80vh;
  input {
    border: none;
    height: 64px;
    padding: 0px 22px;
    font-size: 15px;
    font-weight: 700;
    border-bottom: 0.5px solid #e5e5e5;
    width: 100%;
    background-color: transparent;
    color: #212529;
    &::placeholder {
      color: #212529;
      opacity: 0.25;
    }
  }
}

.inputWrapper {
  height: 80vh;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // background-color: pink;
  padding-top: 50px;
  .inputSection {
    padding-bottom: 50px;
    .inputHeader {
      font-size: 14px;
      font-weight: 700;
      padding-bottom: 19px;
    }
    .inputStyle {
      height: 70px;
      width: 100%;
      border: 0.5px solid #e5e5e5;
      border-radius: 15px;
      padding-left: 24px;
      font-size: 16px;
      font-weight: 700;
      &::placeholder {
        opacity: 0.25;
      }
    }
  }
}
.buttonWrapper {
  height: 10vh;
  // background-color: red;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 0.5px solid #e5e5e5;
  div {
    width: 50%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
}

.prevStep {
  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
}

.listItem {
  display: flex;
  align-items: center;
  height: 74px;
  padding: 0px 22px;
  border-bottom: 0.5px solid #e5e5e5;
  img {
    width: 20px;
    height: 20px;
    border-radius: 5;
  }
  div {
    padding-left: 8px;
    font-size: 15px;
    font-weight: 700;
  }
  &:hover {
    background-color: whitesmoke;
    cursor: pointer;
  }
}

.roundButton {
  border: 0.5px solid #e5e5e5;

  border-radius: 15px;
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.roundButtonSelected {
  border: 0.5px solid #e5e5e5;

  border-radius: 15px;
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  cursor: pointer;
}

.loadingIcon {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  opacity: 0.8;
  z-index: 10;
  img {
    // width: 25%;
    height: 25%;
    max-height: 250px;
    object-fit: contain;
    margin-top: -10vh;
  }
}
