.affiliate {
  width: 100%;
  overflow-y: hidden;
  position: absolute;
  inset: 0;
  overflow-x: hidden;

  .affiliate-navbar {
    width: 100%;
    height: 8%;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;

    .hamIcon {
      cursor: pointer;
      transition: all ease 0.2s;

      &:hover {
        transform: scale(1.1);
      }
    }

    .affiliate-icon {
      width: 10%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .search-affiliate {
      width: 45%;
      height: 100%;
      border: 0.5px solid #ededed;
      // background: rgba(237, 237, 237, 0.37);
      background: white;
      border-radius: 5px;
      padding: 0.5rem 2rem;
      display: flex;
      align-items: center;
      position: relative;
      .all {
        width: 32%;
        height: 100%;
        border-right: 3px solid #e7e7e7;
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #212529;
        font-weight: 700;
        opacity: 0.25;
        gap: 1rem;
        p {
          margin-bottom: 0;
        }
        .arroww {
          display: flex;
          align-items: center;
          img {
            width: 15px;
            height: 15px;
            cursor: pointer;
          }
        }
      }
      .im {
        width: 100%;
        height: 100%;
        // padding-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
        color: #212529;
        font-weight: 700;
        // opacity: 0.25;
        gap: 1rem;
        input {
          border: none;
          font-size: 1rem;
          color: #212529;
          font-weight: 700;
          background: transparent;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .right-topa {
      // width: 37%;
      height: 100%;
      display: flex;
      .divv {
        // width: 50%;
        height: 100%;
        display: flex;
        gap: 1rem;
        align-items: center;
        p {
          margin-bottom: 0;
          font-size: 1rem;
          color: #212529;
          font-weight: 700;
        }
      }
    }

    .w-btn {
      padding: 0.65rem 1.75rem;
      color: #ffffff;
      background: #4b2a91;
      font-size: 0.75rem;
      font-weight: 520;
      border-radius: 25px;
      cursor: pointer;
      transition: all ease 0.2s;
      &:hover {
        transform: scale(1.07);
      }
    }
  }

  .w-space {
    width: 100%;
    height: 4%;
    padding: 0 3rem;
    .optionss {
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      .each-option-selected {
        background: rgba(229, 229, 229, 0.25);
        font-weight: 600;
        padding: 10px 1rem;
        border-radius: 5px;
      }
      .each-option {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom: 0;
          color: #212529;
          font-size: 0.9rem;
        }
        div {
          display: flex;
          gap: 5px;
          transition: all ease 0.3s;
          cursor: pointer;
          &:hover {
            scale: 1.1;
          }
        }
      }
      .hamburger {
        color: #212529;
        font-weight: 700;
        display: flex;
        p {
          margin-bottom: 0;
        }
        .hov-animate {
          transition: all ease 0.3s;
          &:hover {
            scale: 1.08;
          }
        }
      }
      .top-icons {
        display: flex;
        align-items: center;
        height: 100%;
        div {
          height: 100%;
          padding: 0 2rem;
          display: flex;
          border-right: 0.25px solid #e7e7e7;
          align-items: center;
          img {
            height: 50%;
          }
        }
      }
    }
  }

  .affiliate-content {
    width: 100%;
    height: 88%;
    padding: 2% 4% 0;
    overflow-y: scroll;
    background: rgba(237, 237, 237, 0.37);
    border-top: 0.5px solid #e5e5e5;
    .header-contt {
      width: 100%;
      height: 20rem;
      border-radius: 15px;
      padding: 4%;
      position: relative;
      background: #4b2a91;
      .text-contt {
        width: 100%;
        display: flex;
        .left-contt {
          width: 50%;
          .linkstyle {
            color: #212529;
            // opacity: 0.5;
            font-weight: 500;
            font-size: 1rem;
            background: #ffffff;
            padding: 0.25rem 0.75rem;
            min-width: 85%;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            div {
              display: flex;
              width: 10%;
              justify-content: space-between;
            }
          }
        }
        .right-contt {
          width: 50%;
          display: flex;
          gap: 2rem;
          justify-content: flex-end;
          button {
            height: 85%;
            padding: 1rem 1.75rem;
            color: #ffffff;
            font-size: 1.2rem;
            font-weight: 600;
            border: 2px solid #ffffff;
            border-radius: 20px;
            display: flex;
            align-items: center;
            gap: 10px;
            transition: all ease 0.3s;
            background: transparent;
            &:hover {
              scale: 1.09;
            }
          }
        }
      }
      .small-boxes {
        width: 100%;
        height: 16rem;
        position: absolute;
        display: flex;
        justify-content: space-between;
        left: 0;
        top: 60%;
        padding: 0 4%;
        .sbox {
          width: 32%;
          height: 100%;
          background-color: white;
          border-radius: 15px;
          padding: 2rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .headd {
            color: #212529;
            font-size: 1.25rem;
            font-weight: 700;
          }
          .ttl {
            color: #212529;
            font-size: 2rem;
            font-weight: 700;
          }
          .act-inact {
            width: 100%;
            display: flex;
            .actt {
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              color: #212529;
              .countt {
                margin-bottom: 0.25rem;
                font-size: 1.5rem;
                font-weight: 600;
                color: #212529;
              }
            }
            .inactt {
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              color: #212529;
              .countt {
                margin-bottom: 0.25rem;
                font-size: 1.5rem;
                font-weight: 600;
                color: #212529;
              }
            }
          }
        }
      }
    }
    .statistics-cont {
      width: 92%;
      margin: auto;
      height: calc(100% - 20rem);
      padding-top: 11rem;
      .overallcontt {
        height: 100%;
        width: 100%;
        .statt-div {
          height: 2rem;
          width: 100%;
          display: flex;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          align-items: center;
          justify-content: space-between;
          padding: 0 3rem;
          background: #4b2a91;
          div {
            width: 14%;
            color: #ffffff;
            font-size: 0.75rem;
            font-weight: 500;
          }
        }
        .contentt-div {
          width: 100%;
          // height: calc(100% - 2rem);
          // overflow-y: scroll;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          border-left: 1px solid #e7e7e7;
          border-right: 1px solid #e7e7e7;
          border-bottom: 1px solid #e7e7e7;
          .each-contentt {
            width: 100%;
            height: 6rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #e7e7e7;
            padding: 0 3rem;
            cursor: pointer;
            transition: all ease 0.3s;
            div {
              width: 14%;
              color: #212529;
              font-size: 1.25rem;
              font-weight: 650;
            }
            &:hover {
              background: #e7e7e7;
            }
          }
        }
      }
    }
  }

  .affiliate-content1 {
    width: 100%;
    height: 88%;
    .topp-section {
      width: 100%;
      height: 10rem;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;
      padding: 1.5rem 4rem;
      align-items: center;

      .left-balances {
        min-width: 20%;
        display: flex;
        gap: 15px;
        // padding: 15px 0;

        .each-balances {
          color: #212529;
          display: flex;
          flex-direction: column;
          // gap: 10px;
          padding: 5px 0;

          .heading {
            font-size: 1rem;
            white-space: nowrap;
          }

          .amount {
            font-size: 1.5rem;
            font-weight: bold;
          }
        }

        .each-balances1 {
          border-right: 1px solid #e7e7e7;
          padding-right: 15%;
        }

        .each-balances2 {
          padding-left: 8%;
        }
      }

      .right-buttons {
        min-width: 20%;
        // border: 1px solid red;
        font-size: 0.8rem;
        font-weight: bold;
        color: #212529;
        display: flex;
        gap: 1rem;
        align-items: center;

        .fees-button {
          // padding: 0.5rem 1.5rem;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 55%;
          border: 0.5px solid #eeeeee;
          white-space: nowrap;
          // gap: 15px;
          cursor: pointer;
          position: relative;

          .fees-button1 {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            /* padding: 0 0.5rem; */
            padding: 0.5rem 1.5rem;
            gap: 15px;
            align-items: center;
            transition: all ease 0.3s;

            .exchange-icon {
              width: 60%;
              height: 60%;
            }

            &:hover {
              scale: 1.08;
            }
          }
        }

        .dd-options {
          position: absolute;
          display: flex;
          flex-direction: column;
          top: 35px;
          width: 100%;
          z-index: 2;
          background: #ffffff;
          border: 0.5px solid #eeeeee;
          padding: 0.75rem 1.5rem 1.25rem;
          gap: 1.25rem;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          border-top: none;
          div {
            display: flex;
            gap: 15px;
            font-weight: 300;
            transition: all ease 0.3s;

            &:hover {
              scale: 1.08;
            }
          }
          img {
            width: 11%;
            // height: 60%;
          }
        }

        .withdraw-button {
          padding: 0.5rem 1.5rem;
          color: white;
          background: red;
          border: 0.5px solid #ea0f0f;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 55%;
          white-space: nowrap;
          cursor: pointer;
          transition: all ease 0.3s;

          &:hover {
            scale: 1.08;
          }
        }
      }
    }

    .filter-opts {
      width: 100%;
      height: 4rem;
      display: flex;
      padding-left: 3rem;
      border-bottom: 0.5px solid #e5e5e5;
      .filters {
        height: 100%;
        width: 70%;
        border-right: 1px solid #e5e5e5;
        display: flex;
        padding: 0.75rem 0;
        gap: 1.5rem;
        .each-filter {
          border: 0.5px solid #e5e5e5;
          border-radius: 15px;
          display: flex;
          grid-gap: 10px;
          gap: 5px;
          align-items: center;
          padding: 0 1.5rem;
          font-size: 0.8rem;
          font-weight: 600;
          cursor: pointer;
          transition: all ease 0.2s;
          &:hover {
            transform: scale(1.07);
          }
        }
      }
      .compplan {
        border-right: 1px solid #e5e5e5;
        height: 100%;
        width: 10%;
        display: flex;
        justify-content: center;
        gap: 5px;
        align-items: center;
        font-weight: 500;
        font-size: 0.9rem;
      }
      .compplan1 {
        border-right: 1px solid #e5e5e5;
        height: 100%;
        width: 10%;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          font-size: 0.9rem;
          gap: 5px;
          cursor: pointer;
          width: 100%;
          height: 100%;
          transition: all ease 0.2s;
          &:hover {
            transform: scale(1.07);
          }
        }
      }
      .filterIcon {
        height: 100%;
        width: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all ease 0.2s;
        &:hover {
          transform: scale(1.07);
        }
      }
    }

    .products-container {
      // height: calc(88% - 8rem);
      height: calc(100% - 14rem);
      overflow-x: scroll;
      .head-section-products {
        display: flex;
        // overflow-y: scroll;
        height: 3rem;
        display: flex;
        padding: 1rem 4rem;
        background: #fafafa;
        color: #212529;
        font-size: 0.75rem;
        font-weight: 700;
        border-bottom: 0.5px solid #e5e5e5;

        & > div {
          min-width: 15%;
          display: flex;
          padding-right: 5rem;
          justify-content: flex-start;
          white-space: nowrap;
        }
      }
      .balance-data-products {
        // height: calc(100% - 17rem);
        height: calc(100% - 3rem);
        overflow-y: scroll;

        .ind-detailss {
          width: 100%;
          padding: 2rem 4rem;
          display: flex;
          font-size: 1rem;
          font-weight: 700;
          color: #212529;
          border-bottom: 0.5px solid #eeeeee;
          align-items: center;
          cursor: pointer;
          transition: all ease 0.2s;

          &:hover {
            // background: #e7e7e7;
            padding-left: 2rem;
          }

          & > div {
            min-width: 15%;
            display: flex;
            padding-right: 5rem;
            justify-content: center;
            // white-space: nowrap;

            .product-details {
              & > p {
                margin-bottom: 0.25rem !important;
              }
            }
          }
        }
        .products-details {
          & > div {
            justify-content: flex-start;
          }
        }
      }
    }

    .headd-section {
      width: 100%;
      height: 3rem;
      border-bottom: 0.5px solid #e5e5e5;
      display: flex;
      padding: 1rem 4rem;
      background: #fafafa;
      color: #212529;
      font-size: 0.75rem;
      font-weight: 700;
      .type {
        width: 25%;
      }
      .user {
        width: 25%;
      }
      .dd {
        width: 10%;
      }
      .direct {
        width: 15%;
        display: flex;
        padding-right: 5rem;
        justify-content: flex-end;
      }
      .baln {
        width: 10%;
        display: flex;
        justify-content: flex-end;
      }
    }

    .dd-options {
      width: 100%;
      height: calc(100% - 17rem);
      overflow-y: scroll;
      .each-dd {
        width: 100%;
        height: 7.5rem;
        display: flex;
        border-bottom: 0.5px solid #e5e5e5;
        padding: 0 3rem;
        align-items: center;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          background: #e7e7e7;
        }
        .wid {
          height: 100%;
          width: 90%;
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: 550;
          img {
            width: 30px;
            height: 30px;
          }
        }
        .wid1 {
          height: 100%;
          width: 10%;
          display: flex;
          align-items: center;
          font-weight: 550;
          transition: all ease 0.2s;
          &:hover {
            font-weight: 700;
            padding-left: 1rem;
          }
        }
      }
    }

    .balance-data {
      width: 100%;
      height: calc(100% - 17rem);
      // border: 1px solid red;
      overflow-y: scroll;

      .ind-detailss {
        width: 100%;
        padding: 2rem 4rem;
        display: flex;
        font-size: 1rem;
        font-weight: 700;
        color: #212529;
        border-bottom: 0.5px solid #eeeeee;
        align-items: center;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          padding-left: 2rem;
        }

        .type1 {
          width: 25%;
          display: flex;
          align-items: center;
          gap: 10px;

          p {
            margin-bottom: 0;
          }
        }
        .dd1 {
          width: 10%;
        }
        .direct1 {
          width: 15%;
          display: flex;
          justify-content: flex-end;
          padding-right: 5rem;
        }
        .baln1 {
          width: 10%;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  .level-header {
    width: 100%;
    display: flex;
    color: #212529;
    background: #fafafa;
    border: 1px solid #e7e7e7;
    justify-content: space-between;
    padding: 0.5rem 4rem;
    font-size: 0.8rem;
    div {
      width: 24%;
    }
  }

  .leveldataa {
    width: 100%;
    display: flex;
    color: #212529;
    border: 1px solid #e7e7e7;
    justify-content: space-between;
    padding: 2rem 4rem;
    align-items: center;
    div {
      width: 24%;
      p {
        margin-bottom: 0;
      }
    }
  }

  .levell {
    width: 100%;
    height: 13%;
    display: flex;
    color: #212529;
    padding: 2rem 4rem;
    justify-content: space-between;
    border-bottom: 0.25px solid #e7e7e7;
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      p {
        margin-bottom: 0;
      }
    }
    .backarrow {
      cursor: pointer;
      &:hover {
        img {
          scale: 1.1;
          transition: all ease 0.3s;
        }
      }
    }
  }

  .levell-header {
    width: 100%;
    height: 4%;
    display: flex;
    color: #212529;
    background: #fafafa;
    border: 0.25px solid #e7e7e7;
    justify-content: space-between;
    padding: 0.5rem 4rem;
    font-size: 0.8rem;
  }

  .overall-lvl {
    width: 100%;
    height: calc(100% - 33%);
    overflow-y: scroll;

    .levell-data {
      width: 100%;
      display: flex;
      color: #212529;
      border: 0.25px solid #e7e7e7;
      justify-content: space-between;
      padding: 2rem 4rem;
      align-items: center;
      div {
        p {
          margin-bottom: 0;
          color: #212529;
        }
      }
    }
  }

  .adduserSidebar {
    position: absolute;
    right: -100%;
    bottom: 0;
    width: 35%;
    height: 100%;
    z-index: 5;
    background: #ffffff;
    transition: all ease 0.4s;

    .headerr {
      width: 100%;
      height: 93%;
      padding: 3rem 2rem;

      .topp {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          margin-bottom: 0;
          color: #212529;
        }
        .closebttn {
          width: 20%;
          display: flex;
          justify-content: space-between;

          img {
            transition: all ease 0.3s;
            cursor: pointer;

            &:hover {
              scale: 1.1;
            }
          }
        }
      }

      .each-field {
        width: 100%;
        /* height: 10rem; */
        display: flex;
        flex-direction: column;
        padding: 1.25rem 0;
        position: relative;

        .dotdiv {
          position: absolute;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #d80027;
          right: 5%;
          bottom: 33%;
        }

        .dddiv {
          position: absolute;
          width: 12px;
          height: 12px;
          right: 5%;
          bottom: 37%;
          cursor: pointer;
          transition: all ease 0.3s;

          &:hover {
            scale: 1.08;
          }
        }

        .tbddiv {
          width: 20%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid #e7e7e7;
          color: #212529;
          opacity: 0.25;
          font-weight: 700;
        }

        p {
          font-size: 1.25rem;
          color: #212529;
        }

        .inputt-field {
          width: 100%;
          border: 1px solid #e7e7e7;
          height: 5.5rem;
          padding: 0 1rem;
          display: flex;

          .placee {
            &::placeholder {
              opacity: 0.25;
            }
          }

          .placeee {
            &::placeholder {
              font-weight: 700;
              opacity: 0.25;
            }
          }

          input {
            cursor: pointer;
            display: flex;
            height: 100%;
            border: none;
            width: 90%;
          }

          .each-appp1 {
            width: 90%;
            height: 100%;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            justify-content: center;

            p {
              margin-bottom: 0;
              color: #212529;
            }
          }

          .each-appp2 {
            display: flex;
            width: 90%;
            height: 100%;
            gap: 15px;
            align-items: center;

            p {
              margin-bottom: 0;
            }
          }
        }
      }

      .appslist {
        width: 100%;
        height: 70%;
        border: 1px solid #e7e7e7;
        overflow-y: scroll;

        .each-appp {
          width: 100%;
          padding: 2rem 3rem;
          border-bottom: 1px solid #e7e7e7;
          display: flex;
          gap: 15px;
          align-items: center;
          cursor: pointer;

          p {
            margin-bottom: 0;
            color: #212529;
          }
        }

        .each-appp1 {
          width: 100%;
          padding: 2rem 3rem;
          border-bottom: 1px solid #e7e7e7;
          display: flex;
          flex-direction: column;
          cursor: pointer;

          p {
            margin-bottom: 0;
            color: #212529;
          }
        }
      }
    }

    .footerr {
      width: 100%;
      height: 7%;
      border-top: 1px solid #e7e7e7;
      display: flex;

      .goback {
        width: 50%;
        border-right: 1px solid #e7e7e7;
        color: #212529;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        font-weight: 650;
        cursor: pointer;
        transition: all ease 0.3s;

        &:hover {
          font-size: 1.4rem;
        }
      }

      .addd {
        width: 50%;
        color: #212529;
        opacity: 0.25;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        font-weight: 650;
        cursor: not-allowed;
        background: white;
      }

      .addd1 {
        width: 50%;
        color: #ffffff;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        font-weight: 650;
        background: #08152d;
        cursor: pointer;
        transition: all ease 0.3s;

        &:hover {
          font-size: 1.4rem;
        }
      }
    }
  }

  .hammenu {
    position: fixed;
    height: 88%;
    top: 12%;
    border-right: 0.5px solid #e5e5e5;
    border-top: 0.5px solid #e5e5e5;
    width: 20%;
    max-width: 550px;
    background: white;
    z-index: 3;
    padding: 1rem 3rem 2rem;
    transition: all ease-in 0.4s;
    .menu-item {
      width: 100%;
      height: 4rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.15rem;
      font-weight: 100;
      cursor: pointer;
      transition: all ease 0.2s;
      &:hover {
        transform: scale(1.07);
      }
    }
  }

  .editUsername {
    position: fixed;
    height: 88%;
    top: 12%;
    right: 0;
    border-left: 0.5px solid #e5e5e5;
    border-top: 0.5px solid #e5e5e5;
    width: 40%;
    max-width: 550px;
    background: white;
    z-index: 3;
    padding: 2rem;
    transition: all ease-in 0.4s;

    .updatingName {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.5rem;
      color: #08152d;
      display: flex;
      white-space: nowrap;

      & > span {
        font-weight: 600;
      }
    }

    .affiliateLogo {
      width: 50%;
      object-fit: contain;
    }

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;

      & > span {
        font-size: 1.75rem;
        font-weight: 700;
        color: #212529;
      }

      & > div {
        font-size: 1.2rem;
        font-weight: 700;
        color: white;
        background: #e73625;
        border: 1px solid #e7e7e7;
        border-radius: 5px;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .otherFields {
      margin: 30px 0;
      & > div {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 10px;
        position: relative;

        & > input {
          padding: 20px;
          background: #ffffff;
          border: 1px solid #e7e7e7;
          border-radius: 10px;
          width: 100%;
          font-size: 1rem;
          font-weight: 500;
          color: #212529;

          &::placeholder {
            font-size: 1rem;
            font-weight: 700;
            color: #212529;
            opacity: 0.25;
          }
        }

        .usernameIndicator {
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translate(-50%, -50%);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: linear-gradient(0deg, #d80027, #d80027),
            linear-gradient(0deg, #d80027, #d80027), #d80027;
        }
      }
    }

    .confirmBtn {
      position: absolute;
      bottom: 2rem;
      left: 2rem;
      right: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 700;
      cursor: pointer;
      color: #ffffff;
      padding: 20px;
      background: #9fd6df;
      border-radius: 10px;
    }
  }

  .usernameLoading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .overlay {
    position: fixed;
    inset: 0;
    z-index: 2;
    background: transparent;
  }
}
