.pinCard {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 7;
  background: white;
  width: 30vw;
  height: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 35px;
  min-width: 550px;
  min-height: 700px;
  max-width: 720px;
  max-height: 900px;

  > .imageDiv {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 35%;
      // height: 20%;
    }
  }

  .otp-input-wrapper {
    width: 80%;
    justify-content: space-between;
    > div {
      flex: 0 0 20%;
      height: 90px;
      .otp-input {
        width: 100% !important;
        height: 100%;
        border: 2px solid rgba(88, 87, 87, 0.25);
        border-radius: 8px;
        font-size: 28px;
      }
    }
  }
  .otp-input-wrapper-onhold {
    width: 100%;
    justify-content: space-between;
    > div {
      width: 20%;
      height: 80px;
      .otp-input {
        width: 100% !important;
        height: 100%;
        border: none;
        background: #ffffff;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        font-size: 28px;
      }
    }
  }

  .closeDiv {
    width: 100%;
    height: 20%;
    max-height: 80px;
    background: #44c2f4;
    border-radius: 0px 0px 35px 35px;
    font-size: 1.5rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-decoration: none;
  }
}
