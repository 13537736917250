@import '../../static/scss/colours';
@import '../../static/scss/mixin';

.preRegPage {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  .formArea {
    flex: 1;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .loading {
      z-index: 1;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .inpWrap {
      width: 100%;
      height: 70px;
      border-bottom: 1px solid $border-color;
      display: flex;
      align-items: center;
      .input {
        width: 0;
        flex: 1;
        border: none;
        background: none;
        font-weight: 600;
        &::placeholder {
          color: #212529;
        }
      }
      .iconBtn {
        height: 70%;
        padding: 15px;
        &.true {
          cursor: pointer;
          path {
            fill: $app-color;
          }
        }
      }
    }
  }
}
