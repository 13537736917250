.tgl1[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.tgl2 {
  border: 3px solid #e5e5e5;
  cursor: pointer;
  text-indent: -9999px;
  width: 30px;
  height: 18px;
  background: white;
  display: block;
  border-radius: 100px;
  position: relative;
}

.tgl2:after {
  content: "";
  position: absolute;
  top: -1px;
  left: 1px;
  width: 14px;
  height: 14px;
  background: #5f6163;
  border-radius: 90px;
  transition: 0.3s;
}

.tgl1:checked + .tgl2 {
  background: white;
}

.tgl1:checked + .tgl2:after {
  left: calc(100%);
  transform: translateX(-100%);
}

.tgl2:active:after {
  width: 20px;
}
.togglecomp {
  p {
    color: #5f6163;
    font-size: 10px;
    text-align: center;
    margin-top: 3px;
  }
}

.toggle-div {
  margin-left: 22px;
  margin-top: -5px;
}
